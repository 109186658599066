import React from 'react';
// import Image, { FluidObject } from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"


interface Props {
  fluid: any;
  description?: string;
}

const ImageAndDesc = ({ fluid, description }: Props): JSX.Element => {
  // console.log(fluid)
  const imagen =  getImage(fluid.gatsbyImageData)
  return (
    <>
      {/* <Image fluid={fluid} /> */}
      {<GatsbyImage image={imagen} alt=""/>}

      {description && (
        <p
          style={{
            fontFamily: 'Roboto',
            fontSize: 15,
            marginTop: 10,
            color: 'grey',
          }}
        >
          Fuente: {description}
        </p>
      )}
    </>
  );
};

export default ImageAndDesc;

ImageAndDesc.defaultProps = {
  description: '',
};
