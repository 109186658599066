import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Container } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ContentfulPulsoTecnico } from '../../types/graphql-types';
import { usePulsoTecnicoPost } from '../operations/queries';
import PostLayout from '../components/PostLayout';
import LightboxCarousel from '../components/LightboxCarousel';
import ImageAndDesc from '../components/ImageAndDesc';
import { PostPreview as PostPreviewProps } from '../models/PostPreview';
import { PulsoTecnico as PulsoTecnicoProps } from '../models/PulsoTecnico';
import { Table as TableProps } from '../models/Table';
import options from '../utils/getRichTextOptions';
import Airtable from '../components/Airtable';
import IconTitle from '../components/IconTitle';
import USA from '../assets/icons/icon-USA.svg';
import Mexico from '../assets/icons/icon-Mexico.svg';
import Wizard from '../assets/icons/icon-Wizard.svg';
import Index from '../assets/icons/icon-Index.svg';
import Asia from '../assets/icons/icon-Asia.svg';
import Energetico from '../assets/icons/icon-energetico.svg';
import Oro from '../assets/icons/icon-oro.svg';
import Plata from '../assets/icons/icon-Plata.svg';
import Spot from '../assets/icons/icon-Spot.svg';
import Europe from '../assets/icons/icon-Europe.svg';
import Currency from '../assets/icons/icon-Currency.svg';
import StickyBox from 'react-sticky-box';
// import getCategoryColor from '../utils/getCategoryColor';

import { renderRichText } from "gatsby-source-contentful/rich-text"
import useIsClient from '../utils/isClient';
import { HeadApi } from '../components/HeadApi';


interface Props {
  data: {
    contentfulPulsoTecnico: ContentfulPulsoTecnico;
  };
}

// export const Head = ({data}) => {
//   const post: PulsoTecnicoProps = usePulsoTecnicoPost(
//     data.contentfulPulsoTecnico
//   );
//   // console.log("[POST HEAD]", post);
//   return(
//     <HeadApi
//       title={post.title}
//       description={post.description}
//       img={post.fluid?.gatsbyImageData.images.fallback.src}
//     />
//   );
// };

export default function PulsoTecnico({ data }: Props): JSX.Element {
  // const color = getCategoryColor('tecnico');

  const post: PulsoTecnicoProps = usePulsoTecnicoPost(
    data.contentfulPulsoTecnico
  );

  // console.log("[POST TECNICO]", post)
  const postPreview = (): PostPreviewProps => {
    const {
      title,
      description,
      tags,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      content,
      authorObject,
    } = post;
    return {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      content,
      authorObject,
      tags
    };
  };
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  // console.log(post);
  const isClient = useIsClient();
  if(isClient){
    return (
      <>
        <PostLayout post={postPreview()}>
          <h2
            style={{
              paddingRight: '10px',
              paddingLeft: '10px',
              fontSize: '1.3em',
            }}
          >
            {post.description}
          </h2>
          <Grid container>
            <Grid
              // style={{ paddingRight: '10px', paddingLeft: '10px' }}
              item
              xs={12}
              sm={12}
              md={post.recomendacionesTablas ? 4 : 3}
            >
              <StickyBox offsetTop={100} style={{ width: '100%' }}>
                {post.recomendacionesTablas &&
                  post.recomendacionesTablas.map((tabla: TableProps) => {
                    if (tabla.tipo == 'tarjeta') {
                      //tarjeta
                      return (
                        <Container
                          style={
                            mobile ? { paddingLeft: 0, paddingRight: 0 } : {}
                          }
                        >
                          <IconTitle icon={Index} title={tabla.titulo}>
                            <div style={!mobile ? { marginLeft: 40 } : {}}>
                              <Airtable
                                key={tabla.titulo}
                                src={tabla.tabla}
                                description={tabla.descripcion}
                                card={tabla.tipo === 'tarjeta'}
                              />
                            </div>
                          </IconTitle>
                        </Container>
                      );
                    }
                  })}
              </StickyBox>
            </Grid>
            <Grid
              style={{ paddingRight: '10px', paddingLeft: '10px' }}
              item
              xs={12}
              sm={12}
              md={6}
            >
              {post.textMexico && (
                <IconTitle title="México" icon={Mexico}>
                  {renderRichText(post.textMexico, options)}
                </IconTitle>
              )}
              {post.graficasIpc && <LightboxCarousel images={post.graficasIpc} />}
              {post.textUSA && (
                <IconTitle title="Estados Unidos" icon={USA}>
                  {renderRichText(post.textUSA, options)}
                </IconTitle>
              )}
              {post.graficasDji && <LightboxCarousel images={post.graficasDji} />}
              {post.textoEuropa && (
                <IconTitle title="Europa" icon={Europe}>
                  {renderRichText(post.textoEuropa, options)}
                </IconTitle>
              )}
              {post.graficasEuropa && <LightboxCarousel images={post.graficasEuropa} />}
              {post.textoAsia && (
                <IconTitle title="Asia" icon={Asia}>
                  {renderRichText(post.textoAsia, options)}
                </IconTitle>
              )}
              {post.graficasAsia && <LightboxCarousel images={post.graficasAsia} />}
              <Grid item xs={12}>
                {post.indicesInternacionalesTabla && (
                  <IconTitle title="Índices Internacionales" icon={Index}>
                    <Airtable
                      src={post.indicesInternacionalesTabla.tabla}
                      description={post.indicesInternacionalesTabla.descripcion}
                      card={post.indicesInternacionalesTabla.tipo === 'tarjeta'}
                    />
                  </IconTitle>
                )}
              </Grid>
              {post.imagenDivisas && (
                <ImageAndDesc
                  fluid={post.imagenDivisas}
                  description={post.imagenDivisas?.description}
                />
              )}
              {post.textoSpot && (
                <IconTitle title="Spot" icon={Spot}>
                  {renderRichText(post.textoSpot, options)}
                </IconTitle>
              )}
              {post.graficasSpot && (
                <LightboxCarousel images={post.graficasSpot} />
              )}
              {post.textoOtrasDivisas && (
                <IconTitle title="Dólar, Euro, Otras Divisas" icon={Currency}>
                  {renderRichText(post.textoOtrasDivisas, options)}
                </IconTitle>
              )}
              {post.graficasDolarEuroOtrasDivisas && <LightboxCarousel images={post.graficasDolarEuroOtrasDivisas} />}
              <Grid item xs={12}>
                {post.divisasTabla && (
                  <IconTitle title="Principales Divisas" icon={Index}>
                    <Airtable
                      src={post.divisasTabla.tabla}
                      description={post.divisasTabla.descripcion}
                      card={post.divisasTabla.tipo === 'tarjeta'}
                    />
                  </IconTitle>
                )}
              </Grid>
              {post.textoOro && (
                <IconTitle title="Metales Preciosos" icon={Oro}>
                  {renderRichText(post.textoOro, options)}
                </IconTitle>
              )}
              {post.graficasOro && <LightboxCarousel images={post.graficasOro} />}
              {post.textoPlata && (
                <IconTitle title="Metales Industriales" icon={Plata}>
                  {renderRichText(post.textoPlata, options)}
                </IconTitle>
              )}
              {post.graficasPlata && <LightboxCarousel images={post.graficasPlata} />}
              {post.textoEnergetico && (
                <IconTitle title="Petróleo y Commodities de Energia" icon={Energetico}>
                  {renderRichText(post.textoEnergetico, options)}
                </IconTitle>
              )}
              {post.graficasPetroleoYComoditiesDeEnergia && <LightboxCarousel images={post.graficasPetroleoYComoditiesDeEnergia} />}
              {post.tasasMexico && (
                <IconTitle title="Tasas México" icon={Mexico}>
                  {renderRichText(post.tasasMexico, options)}
                </IconTitle>
              )}
              {post.graficasTasasMexico && <LightboxCarousel images={post.graficasTasasMexico} />}
              {post.treasuryEstadosUnidos && (
                <IconTitle title="Treasury EUA" icon={USA}>
                  {renderRichText(post.treasuryEstadosUnidos, options)}
                </IconTitle>
              )}
              {post.graficasTreasuryEstadosUnidos && <LightboxCarousel images={post.graficasTreasuryEstadosUnidos} />}
            </Grid>
            <Grid item xs={12}>
              {post.otrasTablas && (
                <IconTitle
                  title="Otras Tablas"
                  icon={Wizard}
                  // table={recomendaciones}
                >
                  {post.otrasTablas.map((tabla: TableProps) => {
                    return (
                      <>
                        <p
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 500,
                            marginBottom: 10,
                          }}
                        >
                          {tabla.titulo}
                        </p>
                        <Airtable
                          key={tabla.titulo}
                          src={tabla.tabla}
                          description={tabla.descripcion}
                          card={tabla.tipo === 'tarjeta'}
                        />
                      </>
                    );
                  })}
                </IconTitle>
              )}
            </Grid>
            {/* <Grid style={{ paddingLeft: 0, paddingRight: 0 }} item xs={12}>
              <div
                style={{
                  height: 500,
                  backgroundColor: 'black',
                  marginBottom: 100,
                }}
              />
            </Grid> */}
          </Grid>
        </PostLayout>
      </>
    );
  }else{
    return(<></>)
  }

}
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPulsoTecnico(slug: { eq: $slug }) {
      ...pulsoTecnico
    }
  }
`;
